import { mapState } from "vuex";
// import { ANNOUNCE_LIST_ACTION } from '@/store/modules/announce/action';
import ListPageMixin from "@/mixins/ListPageMixin";

const DraftListMixin = {
  mixins: [ListPageMixin],
  data() {
    return {
      API_PATH: `${this.$apiPath.ST_PURCHASE_DRAFT}`,
    };
  },
  beforeMount() {
    const params = this.checkUrlQuery();

    this.getDataInit(params);
  },
  computed: {
    // ...mapState({
    //   statusList: (state) => state.announce.statusList,
    // }),
  },
  methods: {
    // 주소 기준으로 현재 페이지에 필요한 params 가져온다.
    // 주소 기준으로 필터바 초기값을 셋팅한다.
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.keywordList);

      const objDates = this.checkQueryDates(query, "startDate", "endDate");

      return Object.assign({}, objKeyword, objDates, objPaging); //objPaging,
    },
    getFilterbarParams(objData) {
      const { keywordSelectedId, keywordInputText, startDt, endDt, status } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      if (startDt && endDt) {
        params["startDate"] = startDt;
        params["endDate"] = endDt;
      }

      const cateNum = null; //category2 || category1 || category0;

      if (cateNum) {
        params.cateNum = cateNum;
      }

      return params;
    },
  },
};
export default DraftListMixin;
