<template>
  <FilterBarBase @onClickSearch="onClickSearch" @onClickReset="onClickReset">
    <dl>
      <dt>기간조회</dt>
      <dd>
        <!-- <Radio name="filterDate" :dataList="dateDatas" :selectedId.sync="dateSelectedId" /> -->
        <DateRangeQuickSelect :dates.sync="dates" />
      </dd>
      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <SelectBox class="w152" :dataList="keywordDatas" :value.sync="keywordSelectedId" />
          <Input
            class="w690"
            type="text"
            :value.sync="keywordInputText"
            @onEnterKey="onClickSearch"
          />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import Input from "@/components/common/input/Input";
import Radio from "@/components/common/radio/Radio";
import SelectBox from "@/components/common/selectBox/SelectBox";
import DateRangeQuickSelect from "@/components/common/calendar/DateRangeQuickSelect";

import FilterBarMixin from "@/mixins/FilterBarMixin";

export default {
  name: "DraftListFilterBar",
  components: {
    FilterBarBase,
    Input,
    Radio,
    SelectBox,
    DateRangeQuickSelect,
  },
  mixins: [FilterBarMixin],
  props: {
    dateDatas: Array,
    defaultDateSelectedId: String,
  },
  data() {
    return {
      dateSelectedId: this.defaultDateSelectedId,
    };
  },
  methods: {
    onClickSearch() {
      let obj = this.getCommonFilterbarData();

      obj.dateSelectedId = this.dateSelectedId;

      this.$emit("onClickSearch", obj);
    },
    onClickReset() {
      this.dateSelectedId = this.dateSelectedId;
      this.onClickResetFilterBar();
      this.keywordSelectedId = "requestCid";
    },
  },
};
</script>
