<template>
  <ListRow>
    <!-- 전자결재 (진행,완료,반려) -->
    <td>
      <template v-if="rowData.docStatus">
        <button
          class="link_subject align_center"
          @click="$windowOpen(`${computedPaymentPath}/${rowData.docNo}`)"
        >
          {{ rowData.docStatusName }}
        </button>
        <!-- <button
          v-if="isMyPayment"
          class="link_subject align_center"
          @click="$windowOpen(`${computedPaymentPath}/${rowData.docNo}`)"
        >
          {{ rowData.docStatusName }}
        </button>
        <button v-else class="align_center" @click="onClickPaymentReject">
          {{ rowData.docStatusName }}
        </button> -->
      </template>
      <template v-else>
        {{ "-" }}
      </template>
    </td>
    <td>{{ rowData.draftCid }}</td>
    <td class="td_ellip" :data-ellip="rowData.title">
      <!-- 상태값이 고정일 경우 link_point 클레스 추가 -->
      <router-link
        :to="toPath"
        :class="['link_subject']"
        :data-ellip="rowData.title"
        v-html="rowData.title"
      />
    </td>
    <td>
      <button
        :class="['link_subject']"
        @click="$windowOpen(`${$routerPath.PURCHASE_VIEW}/${rowData.requestCid}`)"
      >
        {{ rowData.requestCid }}
      </button>
    </td>
    <td class="align_right">
      {{ rowData.totalDraftPrice | currency | nullToDash }}
    </td>
    <td>{{ rowData.regDate | dateStringFormat }}</td>

    <td
      class="td_ellip align_left"
      :data-ellip="`[${rowData.projectCode}] ${rowData.projectName}`"
      v-html="rowData.projectName"
    ></td>
    <td class="align_left">
      <template v-if="computedItemDisplayCode !== '-'">
        <button
          :class="['link_subject']"
          @click="
            $windowOpen(`${$routerPath.ITEM_VIEW}/${rowData.purchaseDraftGoodsList[0].itemCode}`)
          "
          v-html="computedItemDisplayCode"
        />
      </template>
      <template v-else> - </template>
    </td>
    <td class="align_left" v-html="computedCategoryName" />
    <td>{{ rowData.drafterName }} ({{ rowData.drafterId }})</td>
    <td>{{ rowData.purchaseManagerName }} ({{ rowData.purchaseManagerId }})</td>
  </ListRow>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";
import { getPaymentRouterPath } from "@/utils/paymentPathUtils";
import { makePathWithQuery } from "@/utils/urlUtils";
import LocalStorageManager from "@/LocalStorageManager";
import { ALERT_ACTION } from "@/store/modules/alert/action";
export default {
  name: "DraftListLine",
  components: {
    ListRow,
  },
  mixins: [ListLineMixin],
  computed: {
    toPath() {
      const { status, draftCid } = this.rowData;
      const path = this.$routerPath.DRAFT_VIEW;
      const ret = makePathWithQuery(path, draftCid, this.$route.query);
      return ret;
    },
    computedProjectCode() {
      const { purchaseDraftGoodsList, projectCode } = this.rowData;
      let ret = "";
      if (!projectCode) {
        ret = "-";
      } else {
        const projectCodeList = purchaseDraftGoodsList.reduce((acc, ele, index) => {
          const { projectCode } = ele;
          if (projectCode) {
            acc.push(projectCode);
          }
          return acc;
        }, []);
        const dropList = _.drop(projectCodeList);

        if (dropList.length > 0) {
          ret = `${projectCode} <strong data-ellip="${dropList.join("\n")}">(${
            dropList.length
          })</strong>`;
        } else {
          ret = `${projectCode}`;
        }
      }
      return ret;
    },
    computedProjectName() {
      const { purchaseDraftGoodsList, projectName } = this.rowData;
      let ret = "";
      if (!projectName) {
        ret = "-";
      } else {
        const projectNameList = purchaseDraftGoodsList.reduce((acc, ele, index) => {
          const { projectName } = ele;
          if (projectName) {
            acc.push(projectName);
          }
          return acc;
        }, []);
        const dropList = _.drop(projectNameList);
        if (dropList.length > 0) {
          ret = `${projectName} <strong data-ellip="${dropList.join("\n")}">(${
            dropList.length
          })</strong>`;
        } else {
          ret = `${projectName}`;
        }
      }
      return ret;
    },
    computedItemDisplayCode() {
      const { purchaseDraftGoodsList } = this.rowData;
      const { displayCode } = this.rowData.purchaseDraftGoodsList[0];
      let ret = "";
      if (!displayCode) {
        ret = "-";
      } else {
        const displayCodeList = purchaseDraftGoodsList.reduce((acc, ele, index) => {
          const { displayCode } = ele;
          if (displayCode) {
            acc.push(displayCode);
          }
          return acc;
        }, []);
        const dropList = _.drop(displayCodeList);
        if (dropList.length > 0) {
          ret = `${displayCode} <strong data-ellip="${dropList.join("\n")}">(${
            dropList.length
          })</strong>`;
        } else {
          ret = `${displayCode}`;
        }
      }
      return ret;
    },
    computedCategoryName() {
      const { purchaseDraftGoodsList } = this.rowData;
      const { itemName } = this.rowData.purchaseDraftGoodsList[0];
      let ret = "";
      if (!itemName) {
        ret = "-";
      } else {
        const categoryNameList = purchaseDraftGoodsList.reduce((acc, ele, index) => {
          const { itemName } = ele;
          if (itemName) {
            acc.push(itemName);
          }
          return acc;
        }, []);

        const dropList = _.drop(categoryNameList);
        if (dropList.length > 0) {
          ret = `${itemName} <strong data-ellip="${dropList.join("\n")}">(${
            dropList.length
          })</strong>`;
        } else {
          ret = `${itemName}`;
        }
      }
      return ret;
    },
    isMyPayment() {
      const { username } = LocalStorageManager.shared.getUserData();
      const { drafterId } = this.rowData;
      return username === drafterId;
    },
    computedPaymentPath() {
      const { docStatus } = this.rowData;
      const ret = getPaymentRouterPath(docStatus);
      return ret;
    },
  },
  methods: {
    onClickPaymentReject() {
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
        text: "해당 기안의 열람 권한이 없습니다.",
      });
    },
  },
};
</script>
