<template>
  <PageWithLayout>
    <div ref="b1" class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">기안 관리</h2>
      </div>
      <div class="body_section">
        <DraftListFilterBar
          :keywordDatas="keywordList"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          :defaultStateId="defaultStateId"
          :defaultDates="defaultDates"
          @onClickSearch="onClickSearch"
        />

        <TableHead>
          <template v-slot:headLeft>
            <button
              v-if="isShowAccessBtn(menuId.DRAFT_WRITE)"
              type="button"
              class="btn_tertiary btn_medium"
              @click="onClickGoToWrite"
            >
              <span class="ico_purchase ico_cross_w" />신규 등록
            </button>
          </template>
          <template v-slot:headRight>
            <SelectBoxPageRange
              :currentMaxRowCount.sync="size"
              @update:currentMaxRowCount="onChangeRowCount"
            />
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :isHorizontalScroll="true"
          :scrollWidth="'1500px'"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <!-- 전자결재 -->
            <col style="width: 60px" />
            <!-- 기안번호 -->
            <col style="width: 120px" />
            <!-- 기안명 -->
            <col />
            <!-- 구매번호금액 -->
            <col style="width: 120px" />
            <!-- 기안요청금액 -->
            <col style="width: 120px" />
            <!-- 등록일 -->
            <col style="width: 80px" />

            <!-- 프로젝트명 -->
            <col style="width: 140px" />
            <!-- 품목코드 -->
            <col style="width: 120px" />
            <!-- 품목명 -->
            <col style="width: 120px" />
            <!-- 구매요청자 -->
            <col style="width: 160px" />
            <!-- 구매담당자 -->
            <col style="width: 180px" />
          </template>
          <template v-slot:tr>
            <th>전자결재</th>
            <th>기안번호</th>
            <th>기안명</th>
            <th>구매요청번호</th>
            <th>기안요청금액</th>
            <th>등록일</th>

            <th>프로젝트명</th>
            <th>품목코드</th>
            <th>품목명</th>
            <th>기안자</th>
            <th>구매담당자</th>
          </template>
          <template v-slot:row="slotProps">
            <DraftListLine :rowData="slotProps.rowData" />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="11" class="td_empty">기안이 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import DraftListFilterBar from "@/components/admin/draft/list/DraftListFilterBar";
import DraftListLine from "@/components/admin/draft/list/DraftListLine";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import ListPage from "@/components/shared/ListPage";

import { board } from "@/constants/options";
import PageMixin from "@/mixins/PageMixin";
import DraftListMixin from "@/mixins/draft/DraftListMixin";
import ApiService from "@/services/ApiService";

import LocalStorageManager from "@/LocalStorageManager";
// 테스트
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "DraftList",
  components: {
    PageWithLayout,
    DraftListFilterBar,
    TableHead,
    SelectBoxPageRange,
    ListPage,
    DraftListLine,
    VueHtml2pdf,
  },
  mixins: [PageMixin, DraftListMixin],
  data() {
    return {
      defaultKeywordSelectedId: "requestCid",
      // keywordList 옵션에 따라 computed로 분기.
    };
  },
  computed: {
    getCanNewWriteProcess() {
      return LocalStorageManager.shared.getCanNewWriteProcess();
    },

    keywordList() {
      const arr = [
        { id: "requestCid", code: "requestCid", desc: "기안번호" },
        { id: "title", code: "title", desc: "기안명" },
      ];

      return arr;
    },
  },
  methods: {
    onClickGoToWrite() {
      this.goWrite();
    },
    async goWrite() {
      this.$router.push({
        path: `${this.$routerPath.DRAFT_WRITE}`,
      });
    },
  },
};
</script>
